import { styled } from '@mui/system'

export const Header = styled('header')`
	border-bottom: 1px solid ${({ theme }) => theme.palette.grey.light};
	padding: ${({ theme }) => theme.spacing(2, 3)};
	${({ theme }) => theme.breakpoints.up('md')} {
		padding: ${({ theme }) => theme.spacing(2, 4.5)};
	}
`

export const LogoWrapper = styled('div')`
	max-width: 172px;
	margin: auto;
`
