/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const ArrowheadLeftIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M16.1 18.3L9.7 12l6.4-6.3-.9-.9L7.9 12l7.3 7.2z" />
	</svg>
))
ArrowheadLeftIconSvg.displayName = 'ArrowheadLeftIconSvg'

const ArrowheadLeftIcon = forwardRef((props, ref) => <Icon component={ArrowheadLeftIconSvg} ref={ref} {...props} />)
ArrowheadLeftIcon.displayName = 'ArrowheadLeftIcon'

ArrowheadLeftIcon.defaultProps = {
	...Icon.defaultProps,
}
ArrowheadLeftIcon.propTypes = {
	...Icon.propTypes,
}

export default ArrowheadLeftIcon
export { ArrowheadLeftIconSvg }
