import { styled } from '@mui/system'
import Typography from '../../../atoms/typography'
import Button from '../../button'
import BHLink from '../../link'

const shouldForwardProp = (prop) => !['open', 'anchor'].includes(prop)

const NavMenu = styled('nav', { shouldForwardProp })`
	display: flex;
	flex-direction: column;
`

const NavLinkStyled = styled(BHLink)`
	font-size: ${({ theme }) => theme.typography.pxToRem(20)};
	font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	font-family: ${({ theme }) => theme.typography.fontFamilyAlternative};
	border-bottom: 1px solid ${({ theme }) => theme.palette.grey.border};
	padding: ${({ theme }) => theme.spacing('11px', 0)};
`

const NavItem = styled(Typography)`
	&.menu-item-active {
		${NavLinkStyled} {
			color: ${({ theme }) => theme.palette.primary.main};
			font-weight: ${({ theme }) => theme.typography.fontWeightBold};
		}
	}
`

const NavSubMenu = styled('div', { shouldForwardProp })`
	position: fixed;
	top: 0;
	width: 100%;
	background: ${({ theme }) => theme.palette.white.main};
	height: 100%;
	padding: 18px;
	will-change: transition;
	z-index: 2;
	${({ theme }) => theme.scrollbar};
	${({ anchor }) =>
		anchor === 'left'
			? `
					left: -100%;
					transition: left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
			  `
			: `
					right: -100%;
					transition: right 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
			  `}
	&.nav-open {
		${({ anchor }) => (anchor === 'left' ? 'left: 0%;' : 'right: 0%;')}
		&.nav-moved {
			${({ anchor }) => (anchor === 'left' ? 'left: 100%;' : 'right: 100%;')}
		}
	}
`

const ParentNavTitle = styled(BHLink)`
	margin: 40px auto 16px 14px;
	display: block;
	min-height: 20px;
	font-size: ${({ theme }) => theme.typography.pxToRem(16)};
`

const SubNavLinkStyled = styled(BHLink)`
	padding: 10px 14px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	font-size: ${({ theme }) => theme.typography.pxToRem(14)};
`

const SubContainerNavItem = styled('div')``

const SubNavItem = styled(Typography)`
	&.submenu-item-active {
		${SubNavLinkStyled} {
			color: ${({ theme }) => theme.palette.primary.main};
			font-weight: ${({ theme }) => theme.typography.fontWeightBold};
		}
	}
`

const BackButton = styled(Button)`
	margin: 17px auto 0 14px;
	border: 0;
	font-family: ${({ theme }) => theme.typography.fontFamily};
	font-weight: normal;
	.MuiButton-startIcon > *:nth-of-type(1) {
		font-size: ${({ theme }) => theme.typography.pxToRem(18)};
	}
`

const SubNavCmsBlock = styled('div')`
	padding: 16px 14px;

	img {
		display: block;
		width: 100%;
	}
`

export {
	BackButton,
	NavMenu,
	NavItem,
	NavLinkStyled,
	NavSubMenu,
	SubNavItem,
	SubNavLinkStyled,
	SubContainerNavItem,
	SubNavCmsBlock,
	ParentNavTitle,
}
