/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const SupportIconSvg = forwardRef((props, svgRef) => (
	<svg
		data-name="Livello 1"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M21.54 16.67c-.13-.41-.39-.77-.76-1.02l-4.41-1.91c-.6-.26-1.31-.19-1.84.18l-1.95 1.34c-1.42-.62-3.22-2.43-3.85-3.84l1.34-1.95a1.9 1.9 0 00.18-1.83L8.52 3.55a1.921 1.921 0 00-2.77-.88c-1.32.83-2.97 2.32-3.32 4.75-.4 2.75.99 5.97 4.14 9.55.13.15.27.29.44.44 3.19 2.8 6.09 4.22 8.63 4.22.32 0 .63-.02.95-.07 2.42-.36 3.92-2 4.74-3.32.29-.47.37-1.04.2-1.57zM9.02 8.74l-1.71 2.49.1.3c.64 1.91 3.14 4.41 5.05 5.05l.3.1 2.49-1.71c.18-.12.41-.15.61-.06l4.08 1.73c.18.07.31.22.37.41.06.17.03.35-.06.51-.68 1.09-1.9 2.45-3.84 2.73-2.37.34-5.26-.96-8.58-3.88-.11-.1-.2-.19-.28-.29-2.9-3.3-4.19-6.17-3.85-8.53.29-1.94 1.64-3.16 2.73-3.85.09-.06.2-.09.32-.09a.638.638 0 01.59.4l1.73 4.08c.08.2.06.43-.06.61z" />
	</svg>
))
SupportIconSvg.displayName = 'SupportIconSvg'

const SupportIcon = forwardRef((props, ref) => <Icon component={SupportIconSvg} ref={ref} {...props} />)
SupportIcon.displayName = 'SupportIcon'

SupportIcon.defaultProps = {
	...Icon.defaultProps,
}
SupportIcon.propTypes = {
	...Icon.propTypes,
}

export default SupportIcon
export { SupportIconSvg }
