import { Grid as MuiGrid, Popper as MuiPopper } from '@mui/material'
import { styled } from '@mui/system'
import { ChangeCountryLanguage } from '../change-country-language'
import Menu from '../../molecules/menu'
import { NavMenu } from '../../molecules/menu/menu-first-level.styled'
import StoreLocatorLink from '../footer/storelocator-link'

const shouldForwardProp = (prop) => !['message', 'position'].includes(prop)

const Header = styled('header', { shouldForwardProp })`
	${({ message, position }) =>
		position === 'top' || !message
			? `
			border-bottom: 0;
		`
			: null}
`

const ChangeCountryLanguageStyled = styled(ChangeCountryLanguage)`
	color: ${({ theme }) => theme.palette.primary.main};
	align-items: center;
	.MuiTypography-root {
		font-size: ${({ theme }) => theme.typography.pxToRem(10)};
		margin: 0;
	}
`

const LogoWrapper = styled('div')`
	margin: 0 auto;
	max-width: 172px;
	width: 100%;
`

const MenuStyled = styled(Menu)`
	padding: ${({ theme }) => theme.spacing(0, 0, '18px')};
	${NavMenu} {
		grid-gap: 27px;
		justify-content: center;
	}
`

const GridContainer = styled(MuiGrid)`
	padding: ${({ theme }) => theme.spacing(2, 4)};
	.mini-trigger {
		padding: 0;
	}
`

const CustomerCareLink = styled('div')`
	display: flex;
	align-items: center;
	position: relative;
	cursor: pointer;
`

const StoreLocatorLinkStyled = styled(StoreLocatorLink)`
	align-items: center;
	min-height: initial;
`

const MuiGridLeft = styled(MuiGrid)`
	display: flex;
	padding-top: 4px;
	align-self: baseline;
	align-items: center;

	${StoreLocatorLinkStyled} {
		.MuiButton-startIcon {
			fill: ${({ theme }) => theme.palette.primary.main};
			margin-right: 10px;
		}
	}

	.mini-trigger {
		margin-right: 10px;
	}
`
const MuiGridCenter = styled(MuiGrid)``
const MuiGridRight = styled(MuiGrid)`
	padding-top: 4px;
`
const PopperStyled = styled(MuiPopper)`
	padding-top: 10px;
	z-index: 9999;
	max-width: 260px;
	position: absolute;
	top: 17px;
`

const InnerPopper = styled('div')`
	background-color: ${({ theme }) => theme.palette.primary.main};
	color: ${({ theme }) => theme.palette.primary.contrastText};
	padding: ${({ theme }) => theme.spacing(2)};
	border: 0.5px solid ${({ theme }) => theme.palette.primary.contrastText};
	font-size: ${({ theme }) => theme.typography.pxToRem(12)};
	&::before {
		content: '';
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 12px 10px 12px;
		border-color: transparent transparent ${({ theme }) => theme.palette.primary.main} transparent;
		position: absolute;
		top: 2px;
		left: 20px;
	}
	a {
		color: ${({ theme }) => theme.palette.primary.contrastText};
		text-decoration: underline;
	}
`

export {
	ChangeCountryLanguageStyled,
	GridContainer,
	Header,
	LogoWrapper,
	MenuStyled,
	MuiGridLeft,
	MuiGridCenter,
	MuiGridRight,
	CustomerCareLink,
	StoreLocatorLinkStyled,
	PopperStyled,
	InnerPopper,
}
