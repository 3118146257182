import { Root as StyledRoot } from './sticky-headroom.styled'
import { LogoRoot } from '../../organisms/header/logo.styled'
import { styled } from '@mui/system'
import { UNPINNED, PINNED } from './constants'
import { LinkRoot as MenuLink } from '../../molecules/menu/menu-first-level.styled'
import { ButtonIcon as AccountIcon } from '../../organisms/header/header.styled'
import { ButtonStyled as WishlistIcon } from '../../molecules/mini-wish-list/mini-wish-list.styled'
import { ButtonStyled as CartIcon } from '../../molecules/minicart/minicart.styled'
import { SvgIcon as SearchIcon } from '../../molecules/search/search.styled'
import { HamburgerButton } from '../../organisms/sidebar-navigation/sidebar-navigation.styled'
import {
	CustomerCareLink,
	StoreLocatorLinkStyled,
	ChangeCountryLanguageStyled,
} from '../../organisms/header/header-full.styled'

export const Root = styled(StyledRoot)`
	${({
		mode,
		overlap,
		theme: {
			palette: {
				background: { header },
				white: { main: white },
				primary: { main: primary },
			},
		},
	}) =>
		mode === PINNED || mode === UNPINNED
			? `
             background-color: ${header};
             transition: all 0.2s ease-out, transform 0.2s ease-out;
                ${LogoRoot}{
                    svg{
                        fill: ${primary};
                    }
                }
                ${MenuLink}{
                    color: ${primary};
                    .MuiLink-root{
                        &::after{
                            background-color: ${primary};
                        }
                    }
                }
                ${SearchIcon}{
                    color: ${primary};
                }
                ${AccountIcon}{
                    .MuiSvgIcon-root{
                        color: ${primary};
                    }
                }
                ${WishlistIcon}{
                    .MuiBadge-root >.MuiSvgIcon-root {
                        color: ${primary};
                    }
                }
                ${CartIcon}{
                    .MuiBadge-root >.MuiSvgIcon-root {
                        color: ${primary};
                    }
                }
                ${HamburgerButton}{
                    .MuiBadge-root >.MuiSvgIcon-root {
                        color: ${primary};
                    }
                }
                ${CustomerCareLink}{
                    color: ${primary};
                    >.MuiLink-root{
                        color: ${primary};
                    }
                }
                ${StoreLocatorLinkStyled}{
                    color: ${primary};
                }
                ${ChangeCountryLanguageStyled}{
                    color: ${primary};
                }
             
       `
			: overlap
			? `
            background-color: transparent;
            ${LogoRoot}{
                svg{
                    transition: all 0.2s ease-out;
                    fill: ${white};
                }
            }
            ${MenuLink}{
                transition: color 0.2s ease-out;
                .MuiLink-root{
                    transition: none;
                    &::after{
                        transition: background 0.2s ease-out;
                        background-color: ${white};
                    }
                }
                color: ${white};
            }
            ${SearchIcon}{
                transition: color 0.2s ease-out;
                color: ${white};
            }
            ${AccountIcon}{
                .MuiSvgIcon-root{
                    transition: color 0.2s ease-out;
                    color: ${white};
                }
            }
            ${WishlistIcon}{
                .MuiBadge-root >.MuiSvgIcon-root {
                    transition: color 0.2s ease-out;
                    color: ${white};
                }
            }
            ${CartIcon}{
                .MuiBadge-root >.MuiSvgIcon-root {
                    transition: color 0.2s ease-out;
                    color: ${white};
                }
            }
            ${HamburgerButton}{
                >.MuiSvgIcon-root {
                    color: ${white};
                }
                .MuiBadge-root >.MuiSvgIcon-root {
                    transition: color 0.2s ease-out;
                    color: ${white};
                }
            }
            ${CustomerCareLink}{
                transition: color 0.2s ease-out;
                color: ${white};
                >.MuiLink-root{
                    transition: color 0.2s ease-out;
                    color: ${white};
                }
            }
            ${StoreLocatorLinkStyled}{
                transition: color 0.2s ease-out;
                color: ${white};
            }
            ${ChangeCountryLanguageStyled}{
                transition: color 0.2s ease-out;
                color: ${white};
            }
            `
			: `background-color: ${header};
            
            ${MenuLink}{
                color: ${primary};
                .MuiLink-root{
                    &::after{
                        background-color: ${primary};
                    }
                }
            }
            `};

	:hover {
		${({
			overlap,
			theme: {
				palette: {
					primary: { main: primary },
				},
			},
		}) =>
			overlap
				? `
                color: ${primary};
                ${LogoRoot}{
                        svg{
						    fill: ${primary} !important;
                        }
					}
                ${MenuLink}{
                    color: ${primary};
                    .MuiLink-root{
                        &::after{
                            background-color: ${primary};
                        }
                    }
                }
                ${SearchIcon}{
                    color: ${primary};
                }
                ${AccountIcon}{
                    .MuiSvgIcon-root{
                        color: ${primary};
                    }
                }
                ${WishlistIcon}{
                    .MuiBadge-root >.MuiSvgIcon-root {
                        color: ${primary};
                    }
                }
                ${CartIcon}{
                    .MuiBadge-root >.MuiSvgIcon-root {
                        color: ${primary};
                    }
                }
                ${HamburgerButton}{
                    >.MuiSvgIcon-root {
                        color: ${primary};
                    }
                    .MuiBadge-root >.MuiSvgIcon-root {
                        color: ${primary};
                    }
                }
                ${CustomerCareLink}{
                        color: ${primary};
                        >.MuiLink-root{
                            color: ${primary};
                        }
                }
                ${StoreLocatorLinkStyled}{
                        color: ${primary};
                }
                ${ChangeCountryLanguageStyled}{
                        color: ${primary};
                }
                    `
				: ''}
	}
`
